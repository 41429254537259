.navbar-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #000000;
  width: 100%;
  height: 4rem;
  overflow: clip;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 0.5rem 0.5rem;
  height: 2.5rem;
}

.navbar-brand a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-nav {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0;
  margin: 0;
}

.nav-item {
  flex: 1;
  text-align: center;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 0.75rem;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
}

.nav-link:hover,
.nav-link.active {
  color: #05ff00;
}

.nav-link.active {
  font-weight: 400;
}

.scroll {
  white-space: nowrap;
  background-color: #05ff00;
  padding: 0.5rem 0.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.scroll div {
  display: flex;
  gap: 1em;
}

.scroll p {
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif;
}

.LeftToRight {
  animation: LeftToRight 30s infinite linear;
}

@keyframes LeftToRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 0.75rem;
  }
  .nav-item {
    flex: 1;
  }
  .nav-link {
    color: #fff;
    text-decoration: none;
  }
  .nav-link:hover,
  .nav-link.active {
    color: #05ff00;
  }

  .LeftToRight {
    animation: LeftToRight 50s infinite linear;
  }
}
