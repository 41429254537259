.about-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.aboutGrid {
  display: grid;
  grid-template-columns: 40% auto 10% 15%;
  gap: 20px;
  text-align: justify;
  flex-grow: 1;
  height: 100%;
}

.bold {
  font-weight: 400;
}

.tech-heading {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 100px;
}

.column-1,
.column-2,
.column-3,
.column-4 {
  display: flex;
  flex-direction: column;
  position: relative;
}

.about-me {
  margin-bottom: 30px;
}

.spacers {
  margin-bottom: 200px;
}

.column-4 {
  display: flex;
  align-items: flex-end; 
}

.column-4 img {
  width: 100%;
  height: auto; 
}

.socials {
  margin-top: auto; 
  text-align: right; 
}

.socials a {
  text-decoration: none;
  color: inherit;
  display: block;
  margin-bottom: 10px;
}

.socials a:hover {
  color: #ff00ea;
}

@media (max-width: 768px) {
  .aboutGrid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .column-4 {
    order: 2;
    align-items: flex-end; 
    height: 500px; 
  }

  .column-4 img {
    width: 200px;
  }

  .spacers {
    margin-bottom: 100px;
  }

  .column-1 {
    order: 1;
  }

  .column-2 {
    order: 3;
  }

  .column-3 {
    order: 4;
  }

  .spacers {
    margin-bottom: 20px;
  }
}
