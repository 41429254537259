.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4rem);
  color: white;
  position: relative; 
}

.nadisha-gif {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: auto;
  height: auto;
  max-height: 30rem;
  object-fit: cover;
}

@media (max-width: 600px) {

  .container {
    overflow: hidden; 
  }

  .nadisha-gif {
    position: static; 
    left: auto; 
    width: 80%; 
    max-height: none; 
    height: auto; 
  }

  body.landing-page {
    overflow: hidden;
  }
}
