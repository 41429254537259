.heading2 {
  font-weight: 300;
  font-size: 12px;
}

.row {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  margin-top: 10px;
}

.project-summary{
  text-align: justify;
}

.tech {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #faff00; 
  border-radius: 100px; 
  border: 1px solid black; 
  display: inline-flex; 
  align-items: center;
  justify-content: center;
  box-sizing: border-box; 
  width: auto; 
}

.pink {
  background-color: #ff00ea; 
}

.tech-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 10px;
}

.software {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 40% auto repeat(2, 1fr); 
  grid-template-rows: repeat(4, 1fr); 
  gap: 10px; 
  width: 100%; 
  height: 100%; 
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 30% auto; 
  gap: 20px;
}

.row .grid {
  width: 100%; 
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr; 
  }

  .software {
    margin-top: 10px;
  }

  .empty {
    display: none;
  }

  .heading2 {
    margin-top: 25px;
  }

  .row {
    flex-direction: column; 
    align-items: flex-start; 
  }
}

